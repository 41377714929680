import React from 'react';
import {
	DEBUG,
	REPORTS,
	formatDate
} from "../Constants";


const Connections = (props) => {

	const {restsList} = props;
	console.log(restsList);

	const connTabData = REPORTS[ REPORTS.length - 1];

	console.log(connTabData)
	if (connTabData.id !== 'connections')
		return 'connections data not found in last value of array Constants.REPORTS';


	const tblRow = (item) => {
		return (
			<>
				{connTabData.table.map((tbl, i) => {

					let val = item[tbl.name] != undefined ? item[tbl.name] : '';
					let css = '';

					switch (tbl.name) {
						case "online":
							if (item.online)
								val = 'ПОДКЛЮЧЕНО';
							else {
								val = 'ОТКЛЮЧЕНО';
								css = 'off';
							}
							break;
						case "last_activity":
							val = formatDate(val, false, true);
							break;
						case "ip":
							val = item.ip + ':' + item.port;
							default:

								break;
					}

					return (
						<td key={`res-row-td` + i} className={tbl.name + ' ' + css}>
							{val}
						</td>
					);
				})}
			</>
		);
	};

	return (
		<div className="">
			<table className="table">
				<thead>
				<tr>
					{connTabData.table.map((tbl, i) => {
						return (
							<td key={`tbl-h-td` + i}>
								{tbl.title}
							</td>
						);
					})}
				</tr>
				</thead>
				<tbody>
				{
					restsList.map((item, i) => {
						return (
							<tr key={'res-row' + i}>
								{tblRow(item)}
							</tr>
						)
					})
				}
				</tbody>
			</table>
		</div>
	);
};

export default Connections;