export const DEBUG = /localhost/.test(window.location.href);
export const COOKIE_NAME = 'user';
export const COOKIE_MAX_AGE = 200 * 60 * 60;//seconds

export const EMAIL = 'support@itsgroup.ru';
export const SITE_URL = 'https://itsgroup.ru';

export const REFRESH_RESTS_LIST = 5 * 60 * 1000;//seconds обновлять список ресторанов и их подключений

export const ALL_RESTS_SELECTED = true;
export const STORAGE_RESTS_SELECTED_IDS = 'restsSelectedId';

export const boolRand = () => {
	return Math.random() > 0.5;
};
export const getRandomInt = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const in_array = (val, arr) => {
	return arr.indexOf(val) !== -1;
};
export const array_equal = (targetArr, mustConsistArr) => {
	if (targetArr.length != mustConsistArr.length)
		return false;

	let equalValues = 0;

	mustConsistArr.map(val => {
		if (in_array(val, targetArr))
			equalValues++;
	});
	// console.log(mustConsistArr, targetArr, isConsist);
	return mustConsistArr.length === equalValues;
}
export const array_unset = (arr, val) => {
	const i = (typeof val === 'number') ? val : arr.indexOf(val);

	if(i > -1)
		arr.splice(i, 1);

	return arr;
};
export const restsOnlineLength = (rests) => {
	if (!rests.length)
		return 0;
	let len = 0;
	rests.forEach(rest => {
		if (rest.online)
			len++;
	});
	return len;
};
export const unixTime = () => {
	return Math.round((new Date()).getTime() / 1000);
};
export const formatDate = (dateSrc, toUnixtime = false, withHours = false) => {
	const date = new Date(dateSrc);

	const getFullDayMoth = (val) => {
		return val > 9 ? val : '0' + val;
	};

	if (toUnixtime)
		return Math.round(date.getTime() / 1000);

	let formatted = getFullDayMoth(date.getDate()) + '.' +getFullDayMoth((date.getMonth() + 1)) + '.' +date.getFullYear();

	if (withHours)
		formatted += ' ' + date.getHours() + ':' + date.getMinutes();

	return formatted;
};
export const formatPrice = price => {
	return price.toFixed(REPOST_SUMM_ROUND);
}
export const REPORT_TABS = [
	'tipOplat',
	'byHours',
	'midCheque',
	'byDishes',
	'byDays',
	'byMonths',
	'itogSmena'
];
export const REPORTS = [
	{
		id: 'tipOplat',
		title: 'Выручка по типам оплат',
		table: [
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: false
			},
			{
				name: 'group',
				title: 'Группа',
				canOff: true
			},
			{
				name: 'pay_name',
				title: 'Тип оплаты',
				canOff: true
			},
			{
				name: 'discount_name',
				title: 'Тип скидки',
				canOff: true
			},
			{
				name: 'sum_wo_discount',
				title: 'Сумма без скидки, р.',
				sortable: true
			},
			{
				name: 'discount_sum',
				title: 'Сумма скидки, р.',
				sortable: true
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true
			}
		],
		defSort: {
			field: 'pay_name',
			asc: true
		},
		//фильтры для таблицы
		filters: [
			// 'rest_name',
			'pay_name',
			'discount_name'
		]
	},
	{
		id: 'byHours',
		title: 'Почасовая выручка',
		table: [
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: false
			},
			{
				name: 'group',
				title: 'Группа',
				canOff: true
			},
			{
				name: 'hour',
				title: 'Час закрытия',
				canOff: true,
				isInt: true,
				sortable: true,
			},
			{
				name: 'cheques',
				title: 'Чеков',
				sortable: true,
				isInt: true,
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true
			}
		],
		defSort: {
			field: 'cheques',
			asc: true
		},
	},
	{
		id: 'midCheque',
		title: 'Средний чек',
		table: [
			{
				name: 'day',
				title: 'Учетный день',
				canOff: true,
				checked: true,
				sortable: true
			},
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: true,
				checked: true,
			},
			{
				name: 'group',
				title: 'Группа',
				canOff: true,
				checked: false,
			},
			{
				name: 'mid_summ',
				title: 'Средняя сумма заказа, р.',
				sortable: true
			},
			{
				name: 'cheques',
				title: 'Чеков',
				sortable: true
			},
		],
		defSort: {
			field: 'day',
			asc: true
		},
	},
	{
		id: 'byDishes',
		title: 'Выручка по блюдам',
		table: [
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: false
			},
			{
				name: 'group',
				title: 'Группа',
				canOff: true,
				checked: true,
			},
			{
				name: 'dish',
				title: 'Блюдо',
				canOff: true,
				checked: true,
			},
			{
				name: 'dishes',
				title: 'Количество блюд',
				sortable: true,
				isInt: true,
			},
			{
				name: 'sum_wo_discount',
				title: 'Сумма без скидки, р.',
				sortable: true
			},
			{
				name: 'discount_sum',
				title: 'Сумма скидки, р.',
				sortable: true
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true
			}
		],
		defSort: {
			field: 'dish',
			asc: true
		},
	},
	{
		id: 'byDays',
		title: 'Выручка по дням',
		table: [
			{
				name: 'day',
				title: 'Учетный день',
				canOff: true,
				checked: true,
				sortable: true
			},
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: false,
				checked: false,
				dependFrom: 'group',//скрывать если скрыто это поле
			},
			//при отключении группы- отключать и ТП
			{
				name: 'group',
				title: 'Группа',
				canOff: true,
				checked: true
			},
			// {
			// 	name: 'discount_name',
			// 	title: 'Тип скидки',
			// 	canOff: true,
			// 	checked: false
			// },
			{
				name: 'cheques',
				title: 'Чеков',
				sortable: true
			},
			{
				name: 'sum_wo_discount',
				title: 'Сумма без скидки, р.',
				sortable: true
			},
			{
				name: 'discount_sum',
				title: 'Сумма скидки, р.',
				sortable: true
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true
			}
		],
		defSort: {
			field: 'day',
			asc: true
		},
	},
	{
		id: 'byMonths',
		title: 'Выручка по месяцам',
		table: [
			{
				name: 'month',
				title: 'Месяц',
				canOff: true,
				checked: false,
				sortable: true,
			},
			{
				name: 'rest_name',
				title: 'Торговое предприятие',
				canOff: false,
				dependFrom: 'group',//скрывать если скрыто это поле
			},
			//при отключении группы- отключать и ТП
			{
				name: 'group',
				title: 'Группа',
				canOff: true,
				checked: true
			},
			{
				name: 'cheques',
				title: 'Чеков',
				sortable: true
			},
			{
				name: 'sum_wo_discount',
				title: 'Сумма без скидки, р.',
				sortable: true
			},
			{
				name: 'discount_sum',
				title: 'Сумма скидки, р.',
				sortable: true
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true
			}
		],
		defSort: {
			field: 'month',
			asc: true
		},
	},
	{
		id: "itogSmena",
		reportType: 'bydeposit',
		title: "Итого по смене",
		defSort: {
			field: 'sum_with_discount',
			asc: false
		},
		table: [
			// {
			// 	name: 'num',
			// 	title: '№',
			// 	sortable: false,
			// 	canOff: false,
			// 	isInt: true,
			// },
			{
				name: 'group',
				title: 'Группа',
				sortable: false,
				canOff: false
			},
			{
				name: 'sum_with_discount',
				title: 'Сумма со скидкой, р.',
				sortable: true,
				canOff: false
			},
			{
				name: 'cheques',
				title: 'Чеков',
				canOff: false,
				sortable: true,
				isInt: true,
			},
			{
				name: 'sum_deposit',
				title: 'Сумма депозитов, р.',
				sortable: true
			},
		]
	},
	{
		id: "connections",
		title: "Подключения",
		table: [
			{
				name: 'name',
				title: 'Торговое предприятие'
			},
			{
				name: 'ip',
				title: 'Адрес базы'
			},
			{
				name: 'version',
				title: 'Версия системы'
			},
			{
				name: 'online',
				title: 'Состояние'
			},
			{
				name: 'last_activity',
				title: 'Последняя активность'
			},
		]
	},

];
export const getReportById = id => {
	let result = null;
	REPORTS.map((rep) => {
		if (rep.id === id)
			result = rep;
	});

	return result;
}

export const REPORT_TYPE_DEFAULT = 'tipOplat';//byHours tipOplat
// export const REPORT_TYPE_ITOG_SMENA = 'bydeposit';
export const REPORT_TAB_DEFAULT = 'connections';
export const REPOST_SUMM_ROUND = 2;//округление в суммах
export const REPORT_TABLE_ITOG_TEXT = 'Итого';
export const REPORT_TABLE_ITOG_CSS = 'itog';
export const REPORT_TABLE_SUBITOG_TEXT = 'всего';

export const TEST_RESTS = [
	{
		id: '1',
		name: 'Ресторан на Мойке',
		online: true
	},
	{
		id: '2',
		name: 'Бар Плескавица',
		online: true
	}
];

export const TEST_REPORT_RESULT = [
	{
		rest_name: "ООО Кидалкино",
		pay_name: 'налик',
		sum_wo_discount: 1000.58,
		discount_sum: 100,
		sum_with_discount: 900,
		hour: '00',
		cheques: 20
	},
	{
		rest_name: "ООО Кидалкино",
		pay_name: 'карта',
		sum_wo_discount: 15000.03,
		discount_sum: 100,
		sum_with_discount: 1400,
		hour: '23',
		cheques: 10
	},
	{
		rest_name: "Бар Три Топора",
		pay_name: 'налик',
		sum_wo_discount: 2100.467,
		discount_sum: 0,
		sum_with_discount: 2100,
		hour: '19',
		cheques: 60
	},
	{
		rest_name: "Бар Три Топора",
		pay_name: 'налик',
		sum_wo_discount: 2100.467,
		discount_sum: 0,
		sum_with_discount: 2100,
		hour: '23',
		cheques: 50
	}
];
export const copyClipboard = str => {
	//https://stackoverflow.com/a/65996386
	if (navigator.clipboard && window.isSecureContext) {
		navigator.clipboard.writeText(str).then(null);
	} else {
		// text area method
		let textArea = document.createElement("textarea");
		textArea.value = str;
		// make the textarea out of viewport
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		textArea.remove();

	}

	// return true;
	// alert('Скопировано в буфер обмена');
}
export const objectHasMoreThenOne = obj => {
	return Object.keys(obj).length > 1;
}

